<template>
  <div class="my-package">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
      <NavbarOne
          id="card-top d-md-none d-lg-none" 
        :showNavbar="showNavbar"
        :showCart="showCart"
        :showCartDesktop="showCartDesktop"
        :showSearch="showSearch"
        :showLogo="showLogo"
      >
      </NavbarOne>
    <!-- </section> -->
    <!-- <CustomSection class="section"> -->
      <NavbarOne
        class="d-md-none d-lg-none"
        :showNavbar="showNavbar"
        :showCart="showCart"
        :showCartDesktop="showCartDesktop"
        :showSearch="showSearch"
        :showLogo="showLogo"
      >
      </NavbarOne>
      
      <v-card class="ma-3 mt-16 ma-md-5 mb-lr mb-mt10">
        <custom-section>
          <template>
            <v-row no-gutters>
              <v-col cols="12" md="8" lg="8">
                <v-card class="card-left mb-4 mr-4 mb-shadow" >
                  <custom-section class="mb-pad">
                    <template>
                      <v-row>
                        <v-col class="d-flex child-flex">
                          <v-img :aspect-ratio="16 / 9" src="@/assets/promo.png">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                      </v-row>
                    </template>

                    <v-card class="card-right mb-4 ml-4 mb-t mobile">
                      <custom-section>
                        <template>
                          <v-row>
                            <p class="promo-title">Virtulab Promo Codes</p>
                          </v-row>
                          <v-row>
                            <v-divider></v-divider>
                          </v-row>
                          <v-row >
                            <v-col cols="7" class="discount-column">
                              Discount 10%
                            </v-col>
                            <v-col cols="5" class="apply-copy"  @click="copyTextFun(booking_code)">
                              {{ booking_code }}
                              <v-icon dense color="#00a4b3">
                                mdi-content-copy
                              </v-icon>
                            </v-col>
                          </v-row>
                          <v-row class="coupon">
                            <template>
                              <v-expansion-panels>
                                <v-expansion-panel>
                                  <v-expansion-panel-header
                                    class="panel-header"
                                    hide-actions
                                  
                                  >
                                    <v-row>
                                      <v-col cols="10" class="apply"> 
                                        Detail 
                                      </v-col>
                                      <v-col cols="2"  class="apply"  @click="dialog_arrow = true" v-if="!dialog_arrow"> 
                                        <v-icon color="#00a4b3">
                                          arrow_drop_down
                                        </v-icon>
                                      </v-col>  
                                      <v-col cols="2"  class="apply"  @click="dialog_arrow = false" v-if="dialog_arrow"> 
                                        <v-icon color="#00a4b3">
                                          arrow_drop_up
                                        </v-icon>
                                      </v-col>  
                                    </v-row>
                                    
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content style="margin-top:-25px;">
                                    <!-- <FieldPromo /> -->
                                    <p class="tnc-promo">Terms and Conditions</p>
                                    <ul class="ul-promo">
                                      <li>
                                        Purchase items with minimum transaction IDR 100,000
                                        and apply coupon in one Booking ID
                                      </li>
                                      <li>Valid from May 20, 2022 - May 30, 2022</li>
                                      <li>Valid for all payment methods</li>
                                      <li>Promo offer cannot be combined with any other</li>
                                      <li>Login requires to get the discount</li>
                                    </ul>
                                    <v-row>
                                      <v-col cols="12">
                                        <localized-link :to="`/patient/test`">
                                          <v-btn rounded class="button-search">{{
                                            $t("page.promo.search_branch")
                                          }}</v-btn>
                                        </localized-link>
                                      </v-col>
                                    </v-row>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </template>
                          </v-row>
                        </template>
                        <DialogMenu
                          :tampilkan="dialog"
                          @toggleDialog="(dialog) => toggleDialogMenu(dialog)"
                        />
                      </custom-section>
                    </v-card>
                    
                    <v-card class="mb-deskrip">
                      <v-row>
                        <h1 class="promotion-title">
                          Grab it fast! Discount 10% for Medical Check Up 1A
                        </h1>
                      </v-row>
                      <v-row class="mt-1">
                        <p class="promotion-jargon">
                          Get your medical check up at our nearest lab with special price!
                          <br />
                        </p>
                      </v-row>
                      <v-row class="mt-1">
                        <p class="promotion-content">
                          <b> Today only! For Medical Check Up 1A!</b><br />

                          For you who want to get a medical check up at our nearest
                          virtulab, you will get 10% off now! Get your discount everyday
                          start from <b> May 20, 2022 until May 30, 2022! </b> Enjoy 10%
                          discount <b> up to IDR 100,000 </b> with a minimun transaction
                          of <b> IDR 100,000 </b>.
                          <br />
                        </p>
                        <v-divider></v-divider>
                      </v-row>
                      <v-row class="row-footer">
                        <ul class="ul-left">
                          <li class="promotion-footer">promo period</li>
                          <li class="promotion-footer">order via</li>
                          <li class="promotion-footer">minimum transaction</li>
                        </ul>
                        <ul class="ul-right" style="list-style-type: none">
                          <li class="promotion-footer-right">
                            May 20, 2022 - May 30, 2022
                          </li>
                          <li class="promotion-footer-right">mobile, web</li>
                          <li class="promotion-footer-right">IDR 100,000</li>
                        </ul>
                      </v-row>
                    </v-card>  
                  </custom-section>
                </v-card>
                <v-card class="mt-5 mr-4 mb-pad">
                  <template>
                    <v-card>
                      <v-tabs>
                        <v-tab class="title-tab"> Terms and Conditions </v-tab>
                        <v-tab class="title-tab"> Disclaimer </v-tab>
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <ul>
                                <li>
                                  Purchase items with minimum transaction IDR 100,000
                                  and apply coupon in one Booking ID
                                </li>
                                <li>Valid from May 20, 2022 - May 30, 2022</li>
                                <li>Valid for all payment methods</li>
                                <li>Promo offer cannot be combined with any other</li>
                                <li>Login requires to get the discount</li>
                              </ul>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <ul>
                                <li>
                                  Virtulab has the right to cancel promotion offer.
                                </li>
                                <li>Promo offer cannot be combined with any other</li>
                                <li>Login requires to get the discount</li>
                                <li>
                                  Virtulab reserves the right to change the terms and
                                  conditions without prior notice.
                                </li>
                              </ul>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </v-card>
                  </template>
                </v-card>
              </v-col>
              <v-col cols="12" md="4" lg="4" class="dekstop">
                <v-card class="card-right mb-4 ml-4">
                  <custom-section>
                    <template>
                      <v-row>
                        <p class="promo-title">Virtulab Promo Codes</p>
                      </v-row>
                      <v-row>
                        <v-divider></v-divider>
                      </v-row>
                      <v-row >
                        <v-col cols="7" class="discount-column">
                          Discount 10%
                        </v-col>
                        <v-col cols="5" class="apply-copy"  @click="copyTextFun(booking_code)">
                          {{ booking_code }}
                          <v-icon dense color="#00a4b3">
                            mdi-content-copy
                          </v-icon>
                        </v-col>
                      </v-row>
                      <v-row class="coupon">
                        <template>
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header
                                class="panel-header"
                                hide-actions
                              
                              >
                                <v-row>
                                  <v-col cols="9" class="apply"> 
                                    Detail 
                                  </v-col>
                                  <v-col cols="3"  class="apply"  @click="dialog_arrow = true" v-if="!dialog_arrow"> 
                                    <v-icon color="#00a4b3">
                                      arrow_drop_down
                                    </v-icon>
                                  </v-col>  
                                  <v-col cols="3"  class="apply"  @click="dialog_arrow = false" v-if="dialog_arrow"> 
                                    <v-icon color="#00a4b3">
                                      arrow_drop_up
                                    </v-icon>
                                  </v-col>  
                                </v-row>
                                
                              </v-expansion-panel-header>
                              <v-expansion-panel-content style="margin-top:-25px;">
                                <!-- <FieldPromo /> -->
                                <p class="tnc-promo">Terms and Conditions</p>
                                <ul class="ul-promo">
                                  <li>
                                    Purchase items with minimum transaction IDR 100,000
                                    and apply coupon in one Booking ID
                                  </li>
                                  <li>Valid from May 20, 2022 - May 30, 2022</li>
                                  <li>Valid for all payment methods</li>
                                  <li>Promo offer cannot be combined with any other</li>
                                  <li>Login requires to get the discount</li>
                                </ul>
                                <v-row>
                                  <v-col cols="12">
                                    <localized-link :to="`/patient/test`">
                                      <v-btn rounded class="button-search">{{
                                        $t("page.promo.search_branch")
                                      }}</v-btn>
                                    </localized-link>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </template>
                      </v-row>
                    </template>
                    <DialogMenu
                      :tampilkan="dialog"
                      @toggleDialog="(dialog) => toggleDialogMenu(dialog)"
                    />
                  </custom-section>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </custom-section>
      </v-card>
    <!-- </CustomSection>       -->
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
//import TextField from "@/components/v2/input/text_field.vue";
// import FieldPromo from "@/views/v2/Patient/Promo/FieldCopy.vue";
//import Cart from "@/components/headers/Cart.vue";
//import UserCard from "@/components/v2/sidebar/UserCard.vue";
import DialogMenu from "@/components/v2/dialog/dialogMenu.vue";
//import notification from "@/components/v2/notification/index.vue";
//import icons from "@/components/elements/Icons.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";

export default {
  components: {
    CustomSection,
    //TextField,
    // FieldPromo,
    //Cart,
    //UserCard,
    DialogMenu,
    //notification,
    //icons,
    NavbarOne
  },
  data: () => ({
    showNavbar: true,
    showCart: true,
    showCartDesktop: true,
    showSearch: true,
    showLogo: false,
    dialog: false,
    testBtn: "",
    packages_check: [],
    getCategory: [],

    getTestItem: [],
    checkFilter: false,
    tab: null,
    booking_code:'QAJ12',
    dialog_arrow: false,
  }),
  methods: {
    formater(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async openDialog() {
      this.dialog = true;
    },
    toggleDialogMenu(toggle) {
      this.dialog = toggle;
    },
     copyTextFun(data) {
      navigator.clipboard.writeText(data);
      this.toast("success", this.$t("text_copied"));
    },
     toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.my-package {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  a {
    text-decoration: none;
  }
  .h-full {
    height: 100%;
    border: 1px solid #777777;
  }
  &__section {
    padding: 0 20px;
  }
}

.column-back {
  margin-left: -13px;
  margin-top: -4px;
  margin-right: 10px;
}

.cart {
  margin-top: -20px;
}

.text-field {
  margin-left: -40px;
}
.title-page {
  color: $blue_1;
  margin-top: 10px;
  font-size: 14px;
  text-transform: capitalize !important;
}

.text-field {
  max-width: 70%;
  margin-top: -3px;
  margin-left: -25px;
}

.card-top {
    top: 0;
    z-index: 999;
    width: 100% !important;
  }

.card-col {
  padding: 10px 20px;
}

.promo-title {
  font-weight: bold;
  font-size: 18px;
  color: #00a4b3;
}

.card-right {
  padding-left: 10px;
  padding-top: 10px;
  margin-left: 2px !important;
}

.apply-button {
  cursor: pointer;
  font-weight: bold;
  text-transform: capitalize;
  color: white;
  padding-top: 6px;
  margin-left: 114px;
}

.promotion-title {
  margin-left: 10px;
  font-size: 20px;
  color: #1f3c87;
}

.promotion-jargon {
  margin-left: 10px;
  margin-top: 10px;
  font-size: 16px;
  color: #00a4b3;
}

.promotion-content {
  margin-left: 10px;
  font-size: 14px;
  text-align: justify;
}

.tnc {
  margin-left: 10px;
}

.title-tab {
  text-transform: capitalize;
}

.ul-right {
  margin-left: 10px;
}

.promotion-footer {
  font-size: 14px;
  text-transform: capitalize;
  color: #777777;
}

.promotion-footer-right {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: bold;
}

.row-footer {
  margin-top: 30px;
  margin-bottom: 0px;
}

.panel-header {
  text-transform: capitalize;
  margin-left: 21px;
  margin-top: 10px;
}

.tnc-promo {
  margin-left: -10px;
  font-size: 12px;
  margin-top: -5px;
  font-weight: bold;
}

.v-application--is-ltr .v-expansion-panel-header__icon {
  margin-left: auto;
  margin-right: -52px !important;
}

.ul-promo {
  font-size: 14px;
  margin-left: -20px;
}

.button-search {
  color: white;
  text-transform: capitalize;
  background-color: #00a4b3 !important;
  display: block;
  margin-top: 20px;
  display: flex;
  width: 100% !important;
  font-size: 12px;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: 25px;
}

.discount-column {
  font-weight: 500;
}

.apply {
  color: #1f3c87;
  font-weight: bold;
  padding: 10px;
  margin-top: -45px;
  margin-left: -30px;
}

.apply-copy {
  color: #00a4b3;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  text-align: center;
}

.arrow-back {
  cursor: pointer;
  margin-top: -8px;
}

.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 97%;
  margin-top: -18px;
}
.mobile{
    display: none;
}
 .dekstop{
    display: block;
  }
.mb-deskrip{
  box-shadow: none !important;
  padding: 10px;
  margin-top: 10px;
}  
@media screen and (max-width: 912px) {
  .mobile{
    display: block;
  }
  .dekstop{
    display: none;
  }
  .mb-shadow{
    box-shadow: none !important;
    margin: 2px 0 0 0 !important;
  }
  .mb-pad{
    margin-right: 0 !important;
    padding: 0!important;
  }
  .mb-deskrip{
    padding: 15px !important;
    box-shadow: none !important;
  }
  .mb-t{
    margin-top: 15px !important;
  }
  .mb-lr{
    margin-left: 0!important;
    margin-right: 0!important;
  }
  .panel-header{
    margin-left: 0 !important;
  }
  .apply{
    margin-left: -10px;
  }
  .promotion-content{
    margin: 10px;
  }
  .mb-mt10{
    margin-top: 64px !important;
  }
  .card-right {
    margin-left: 0 !important;
  }
  .section{
    padding: 0!important;
  }
}

@media screen and (max-width: 600px) {
  .ul-left {
    li {
      font-size: 10px;
    }
  }

  .ul-right {
    li {
      font-size: 10px;
    }
  }

  .column-back {
    margin-right: 0px;
    margin-left: -16px;
    margin-top: -5px;
  }

  .back {
    cursor: pointer;
  }

  .text-field {
    max-width: 87% !important;
    margin-left: 1px;
  }

  .cart-right {
    margin-left: -46px !important;
  }

  .bell-mobile {
    margin-left: 8px;
  }
  .user-card {
    max-width: 60% !important;
    margin-left: 142px;
    margin-right: 1px;
    margin-top: -38px;
  }

  .card-top {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  #card-top{
    display: none;
  }
  .notification {
    height: 40px;
    border-radius: 3px;
    margin-top: -7px;
    margin-left: -70px;
  }
  .cart {
    margin-left: 11px;
    margin-right: 8px;
    z-index: 1;
  }

  .card-right {
    margin-left: 0 !important;
  }

   .mobile{
    display: block;
  }
  .dekstop{
    display: none;
  }
  .mb-shadow{
    box-shadow: none !important;
    margin: 2px 0 0 0 !important;
  }
  .mb-pad{
    margin-right: 0 !important;
    padding: 0!important;
  }
  .mb-deskrip{
    padding: 15px !important;
    box-shadow: none !important;
  }
  .mb-t{
    margin-top: 15px !important;
  }
  .mb-lr{
    margin-left: 0!important;
    margin-right: 0!important;
  }
  .panel-header{
    margin-left: 0 !important;
  }
  .apply{
    margin-left: -10px;
  }
  .promotion-content{
    margin: 10px;
  }
  .mb-mt10{
    margin-top: 10px !important;
  }
  .section{
    padding: 0!important;
  }
}
</style>
